import { Severity } from '@sentry/types'

export const state = () => ({
  pages: []
})

export const getters = {
  getList: (state) => {
    return state.pages
  }
}

export const mutations = {
  setList (state, pages) {
    state.pages.splice(0, state.pages.length, ...pages)
  }
}

export const actions = {
  setList ({ commit }) {
    return this.$static.getPageList('pages', 5)
      .then((data) => {
        commit('setList', Object.freeze(data))
      }).catch(() => {
        this.$sentry.captureMessage('Ошибка при получении списка страниц', Severity.Warning)
      })
  }
}
