export const state = () => ({
  appeals: []
})

export const getters = {
  getList: (state) => {
    return state.appeals
  }
}

export const mutations = {
  add (state, appeals) {
    state.appeals.push(...(appeals || []))
  },
  save (state, appeal) {
    if (state.appeals.some(i => i.uin === appeal.uin)) {
      return
    }
    state.appeals.unshift(appeal)
  },
  delete (state, appealsId) {
    state.appeals.splice(
      state.appeals.findIndex(item => item.uin === appealsId),
      1
    )
  },
  clean (state) {
    state.appeals = []
  }
}
