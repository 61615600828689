import Exception from '~/types/Exception'

export const state = () => ({
  subjects: []
})

export const getters = {
  getList: (state) => {
    return state.subjects
  },
  getCount: (state) => {
    return state.subjects.length
  },
  searchByName: state => (name) => {
    return state.subjects.filter(item => item.name && item.name.toLowerCase().includes(name.toLowerCase()))
  }
}

export const mutations = {
  getList (state, subjects) {
    state.subjects.splice(0, state.subjects.length, ...subjects)
  }
}

export const actions = {
  getList ({ commit }, regionId) {
    /**
     * Преобразуем элемент массива
     * @param {object} item - district
     * @param {string} queryName - namr of query
     * @returns {{name: *, url: string}} - result list
     */
    const modifyData = (item, queryName) => {
      return {
        name: item,
        url: '/divisions?' + require('qs').stringify({ region_id: regionId, [queryName]: item })
      }
    }

    return this.$static.getGibddRegionInfo(regionId).then((data) => {
      const districts = data.district_list.filter(item => !!item).map(item => modifyData(item, 'district'))
      const cities = data.city_list.filter(item => !!item).map(item => modifyData(item, 'city'))

      if (districts.concat(cities).length === 0) {
        throw new Exception(404)
      }

      commit('getList', districts.concat(cities).sort((a, b) => a.name > b.name ? 1 : -1))
    }
    )
  }
}
