import { Severity } from '@sentry/types'

export const state = () => ({
  reviews: []
})

export const getters = {
  getList: (state) => {
    return state.reviews
  }
}

export const mutations = {
  setList (state, reviews) {
    state.reviews.splice(0, state.reviews.length, ...reviews)
  }
}

export const actions = {
  setList ({ commit }) {
    return this.$axios.get(
      process.env.API_ENDPOINT + 'comments?client_app_id=' + process.env.APP_ID + '&page=0&page_size=2',
      { cache: true, requireToken: false }
    ).then((response) => {
      commit('setList', Object.freeze(response.data.data))
    }).catch(() => {
      this.$sentry.captureMessage('Ошибка при получении списка отзыов', Severity.Warning)
    })
  }
}
