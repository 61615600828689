const isAvailableStorage = () => {
  try {
    return 'localStorage' in window && window.localStorage !== null
  } catch (e) {
    return false
  }
}

export const state = () => ({
  userDocs: {},
  offer: {}
})

export const getters = {
  getDocs: (state) => {
    return state.userDocs
  },
  getOffer: (state) => {
    return state.offer
  },
  isUserDataToLocalStorage: () => {
    return !!localStorage.getItem('userOsagoDocs')
  },
  isOfferToLocalStorage: () => {
    return !!localStorage.getItem('offerOsago')
  }
}

export const mutations = {
  getDocsFromLocalStorage (state) {
    if (localStorage.getItem('userOsagoDocs')) {
      state.userDocs = JSON.parse(localStorage.getItem('userOsagoDocs'))
    }
  },
  setUserDocs (state, docs) {
    state.userDocs = { ...state.userDocs, ...docs }
    localStorage.setItem('userOsagoDocs', JSON.stringify({ ...state.userDocs, ...docs }))
  },
  setOffer (state, data) {
    state.offer = data
    localStorage.setItem('offerOsago', JSON.stringify(state.offer))
  },
  getOfferFromLocalStorage (state) {
    if (localStorage.getItem('offerOsago')) {
      state.offer = JSON.parse(localStorage.getItem('offerOsago'))
    }
  },
  deleteDoc (state, data) {
    delete state.userDocs[data]
    localStorage.setItem('userOsagoDocs', JSON.stringify(state.userDocs))
  },
  clearLocalStorage () {
    localStorage.removeItem('userOsagoDocs')
    localStorage.removeItem('offerOsago')
  },
  clearStore (state) {
    state.userDocs = {}
    state.offer = {}
  }
}

export const actions = {
  getDocsFromLocalStorage ({ commit }) {
    if (isAvailableStorage()) {
      commit('getDocsFromLocalStorage')
    }
  },
  getOfferFromLocalStorage ({ commit }) {
    if (isAvailableStorage()) {
      commit('getOfferFromLocalStorage')
    }
  },
  setUserDocs ({ commit }, docs) {
    if (isAvailableStorage()) {
      commit('getDocsFromLocalStorage')
      commit('setUserDocs', docs)
    }
  },
  setOffer ({ commit }, data) {
    if (isAvailableStorage()) {
      commit('setOffer', data)
    }
  },
  deleteDoc ({ commit }, item) {
    if (isAvailableStorage()) {
      commit('getDocsFromLocalStorage')
      commit('deleteDoc', item)
    }
  },
  clearLocalStorage ({ commit }) {
    if (isAvailableStorage()) {
      commit('clearStorage')
    }
  },
  clearStore ({ commit }) {
    commit('clearStore')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
