/**
 * find question list for index page
 *
 * @param {*} context - nuxt context
 * @returns {*} promise
 */
export default function (context) {
  return Promise.all([
    context.store.dispatch('news/setList'),
    context.store.dispatch('pages/setList'),
    context.store.dispatch('reviews/setList')
  ]).catch(() => {})
}
